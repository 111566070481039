/*
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-05-21 16:02:42
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-08-30 16:51:51
 * @FilePath: \qctsw-vue\packages\app\src\logic\home\seriesTabList.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { LOAD_STATE } from '@/shared/types'
import type { SeriesTabListResponse } from '@/server/types'
import { getSeriesTabListApi } from '@/server'

export function logicSeriesTabList() {
  const _SIZE = 30
  const _PARAMS = { pageSize: _SIZE }

  const state = ref<LOAD_STATE>(LOAD_STATE.INIT)
  const activeTab = ref(0)
  const tabs = reactive<{ name: string, list: SeriesTabListResponse['series'] }[]>([
    { name: '热门投诉', list: [] },
  ])

  function format(data: SeriesTabListResponse) {
    const { series } = data
    const hotSeriesName: string[] = []
    const grades = data.seriesEnergyTypes || []
    tabs.splice(1)
    tabs[0].list = []

    grades.forEach((v) => {
      tabs.push({ name: v, list: [] })
    })
    for (const item of series) {
      const seriesGrade = item?.seriesEnergyType || item.seriesGrade
      if (!seriesGrade)
        continue

      const index = grades.indexOf(seriesGrade)
      if (index >= 0)
        tabs[index + 1].list.push(item)
      else if (tabs[tabs.length - 1].name.includes('其他'))
        tabs[tabs.length - 1].list.push(item)

      if (tabs[0].list.length < _SIZE && !hotSeriesName.includes(item.carSeriesName)) {
        hotSeriesName.push(item.carSeriesName)
        tabs[0].list.push(item)
      }
    }
  }

  async function getTabList() {
    const res = await useLoadState({
      key: `SERIES_TAB_LIST`,
      state,
      fn: () => getSeriesTabListApi(_PARAMS),
    })

    res && format(res.data)
  }

  return {
    state,
    activeTab,
    tabs,
    getTabList,
  }
}
